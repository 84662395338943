'use client';

import { Settings } from '@/models/settings';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';

export function PHProvider({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    const initPosthog = async () => {
      const settings = (await Settings.load()).settingsData;

      if (settings.posthog) {
        posthog.init(settings.posthog.apiKey, {
          api_host: settings.posthog.apiHost,
          person_profiles: 'identified_only',
          capture_pageleave: true,
        });
      }
    };

    initPosthog();
  }, []);
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
