'use client';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { cn } from '@/lib/utils';
import { InstallationData } from '@/models/installation';
import { InstallationsData } from '@/models/installations';
import { Loader2 } from 'lucide-react';
import Image from 'next/image';

interface InstallationSwitcherProps {
  className?: string;
  currentInstallation: InstallationData | null;
  setCurrentInstallation: (installation: InstallationData | null) => void;
  installationsData: InstallationsData | null;
}

export function InstallationSwitcher({
  className,
  currentInstallation,
  setCurrentInstallation,
  installationsData,
}: InstallationSwitcherProps) {
  return installationsData ? (
    <Select
      defaultValue={currentInstallation?.name}
      value={currentInstallation?.name}
      onValueChange={(value) =>
        setCurrentInstallation(
          installationsData.installations.find(
            (installation) => installation.name === value,
          ) || null,
        )
      }
    >
      <SelectTrigger
        className={cn(
          'flex max-w-fit items-center gap-2 overflow-hidden [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0',
          className,
        )}
        aria-label="Select installation"
      >
        <SelectValue
          className="bg-popover"
          placeholder="Select an installation"
        >
          {currentInstallation?.avatarUrl ? (
            <Image
              className="h-6 w-6 rounded-full"
              src={currentInstallation.avatarUrl}
              width={24}
              height={24}
              alt="avatar"
            />
          ) : (
            <></>
          )}
          <span className={cn('ml-2')}>
            {
              installationsData.installations.find(
                (installation) =>
                  installation.name === currentInstallation?.name,
              )?.name
            }
          </span>
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>App Installations</SelectLabel>
          {installationsData.installations.map((installation) => (
            <SelectItem key={installation.name} value={installation.name}>
              <div className="flex items-center gap-3 [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0 [&_svg]:text-foreground">
                {installation.avatarUrl ? (
                  <Image
                    className="h-6 w-6 rounded-full"
                    src={installation.avatarUrl || ''}
                    alt="avatar"
                    width={24}
                    height={24}
                  />
                ) : (
                  <></>
                )}
                {installation.name}
              </div>
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  ) : (
    <Select disabled>
      <div className="flex justify-center">
        <SelectTrigger
          className={cn(
            'flex items-center gap-2 [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0',
          )}
          aria-label="Loading"
        >
          <SelectValue placeholder="Loading Installations...">
            <Loader2
              className={cn('my-28 h-16 w-16 animate-spin text-primary/60')}
            />
          </SelectValue>
        </SelectTrigger>
      </div>
    </Select>
  );
}
