'use client';

import { useEffect, useRef } from 'react';

import { refreshToken } from '@/lib/auth';
import { useStore } from '@/lib/zustand';
import { Settings } from '@/models/settings';
import useAuthStore from '@/stores/auth-store';
import { useRouter } from 'next/navigation';

export function AuthValidationProvider() {
  const router = useRouter();

  const authStore = useStore(useAuthStore, (state) => state);
  const authStoreRef = useRef(authStore);

  useEffect(() => {
    const onLoggedIn = () => {
      authStoreRef.current.setLoggedIn();
    };
    const onLoggedOut = () => {
      authStoreRef.current.setLoggedOut();
      router.push('/login');
    };

    Settings.load().then((settings: Settings) => {
      if (settings.settingsData.__dev__?.service_account_key) {
        return;
      }

      refreshToken(settings.settingsData.apiUrl, onLoggedIn, onLoggedOut);
      setInterval(
        () => {
          refreshToken(settings.settingsData.apiUrl, onLoggedIn, onLoggedOut);
        },
        (settings.settingsData.accessTokenExpiration / 2) * 1000,
      );
    });

    // should only run once per load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}
