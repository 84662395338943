'use client';

import { useStore } from '@/lib/zustand';
import { Installations } from '@/models/installations';
import { Repositories } from '@/models/repositories';
import { Settings } from '@/models/settings';
import useAuthStore from '@/stores/auth-store';
import useSessionStore from '@/stores/session-store';
import posthog from 'posthog-js';
import { useEffect, useRef } from 'react';

export function SessionProvider() {
  const sessionStore = useStore(useSessionStore, (state) => state);
  const sessionStoreRef = useRef(sessionStore);
  const authStore = useStore(useAuthStore, (state) => state);

  useEffect(() => {
    const loadSession = async () => {
      let { settingsData: settings } = sessionStoreRef.current;
      if (!settings) {
        settings = (await Settings.load()).settingsData;
        sessionStoreRef.current.setSettingsData(settings);
      }

      if (settings.__dev__ && settings.__dev__.service_account_key) {
        authStore.setLoggedIn();
        const { installationsData } = await Installations.load(
          settings.apiUrl,
          settings.__dev__.service_account_key,
        );
        sessionStoreRef.current.setInstallationsData(installationsData);

        if (
          (installationsData?.installations?.length ?? 0) > 0 &&
          !sessionStoreRef.current.currentInstallation
        ) {
          sessionStoreRef.current.setCurrentInstallation(
            installationsData?.installations[0] ?? null,
          );
        }
      } else if (!authStore.isLoggedIn) {
        sessionStoreRef.current.clearSession();
      } else {
        let { installationsData } = sessionStoreRef.current;
        if (!installationsData?.installations) {
          installationsData = (await Installations.load(settings.apiUrl))
            .installationsData;
          sessionStoreRef.current.setInstallationsData(installationsData);
        }

        if (
          (installationsData?.installations?.length ?? 0) > 0 &&
          !sessionStoreRef.current.currentInstallation
        ) {
          sessionStoreRef.current.setCurrentInstallation(
            installationsData?.installations[0] ?? null,
          );

          posthog.identify(new Date().toUTCString(), {
            name: installationsData.installations[0].name,
          });
        }
      }
    };

    loadSession();
  }, [authStore]);

  useEffect(() => {
    const loadRepositories = async () => {
      if (sessionStore.apiProxy) {
        const repositories = await Repositories.load(sessionStore.apiProxy);
        sessionStoreRef.current.setRepositories(repositories);
      }
    };

    loadRepositories();
  }, [sessionStore.apiProxy]);

  return <></>;
}
