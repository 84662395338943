import(/* webpackMode: "eager" */ "/home/timthacker/nullify/galactic-empire/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/timthacker/nullify/galactic-empire/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/timthacker/nullify/galactic-empire/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"DM_Sans\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"DMSans\"}");
;
import(/* webpackMode: "eager" */ "/home/timthacker/nullify/galactic-empire/src/app/_components/posthog-page-view.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SiteHeader"] */ "/home/timthacker/nullify/galactic-empire/src/app/_components/site-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthValidationProvider"] */ "/home/timthacker/nullify/galactic-empire/src/app/_providers/auth-validation-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/home/timthacker/nullify/galactic-empire/src/app/_providers/posthog-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/home/timthacker/nullify/galactic-empire/src/app/_providers/session-provider.tsx");
;
import(/* webpackMode: "eager" */ "/home/timthacker/nullify/galactic-empire/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/timthacker/nullify/galactic-empire/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ApiProxy"] */ "/home/timthacker/nullify/galactic-empire/src/lib/api-proxy.ts");
