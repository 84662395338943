import { z } from 'zod';
import { Installation, InstallationSchema } from './installation';

const InstallationsDataSchema = z.object({
  installations: z.array(InstallationSchema),
});
export type InstallationsData = z.infer<typeof InstallationsDataSchema>;

export class Installations {
  private _installations: Installation[];

  private _installationsData: InstallationsData;

  private constructor(installationsData: InstallationsData) {
    this._installations = installationsData.installations.map(
      (installation) => new Installation(installation),
    );
    this._installationsData = installationsData;
  }

  get installations() {
    return this._installations;
  }

  get installationsData() {
    return this._installationsData;
  }

  static async load(
    apiUrl: string,
    service_account_key?: string,
  ): Promise<Installations> {
    const response = await fetch(`${apiUrl}/admin/installations`, {
      credentials: 'include',
      headers: service_account_key
        ? { Authorization: `Bearer ${service_account_key}` }
        : {},
    });

    if (!response.ok) {
      throw new Error(
        `Failed to load installations from ${apiUrl}, status: ${response.status}`,
      );
    }

    const body = await response.json();
    const installationsData = InstallationsDataSchema.parse(body);
    return new Installations(installationsData);
  }
}
